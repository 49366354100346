@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

// Adapted author presentation for 'representants'
.representants .h-card {
    @include breakpoint($large) {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
}
.representants .author__avatar {
  display: block;
  width: auto;
  height: auto;

  @include breakpoint($large) {
    display: table-cell;
    width: 150px;
    vertical-align: top;
    width: 36px;
    height: 36px;
  }

  img {
    padding: 5px;
    border: 1px solid $border-color;

    @include breakpoint($large) {
      max-width: 150px;
      border-radius: 50%;
    }
  }
}

.representants .author__content {
  display: block;
  width: 100%;
  padding-left: 10px;

  @include breakpoint($large) {
    display: table-cell;
    width: 200px;
    vertical-align: top;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 25px;
    line-height: 1;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.representants .author__name {
  margin-top: 10px;
  margin-bottom: 10px;

  @include breakpoint($large) {
    width: 200px;
    margin: 0;
  }
}
.representants .author__name {
  font-family: $sans-serif;
  font-size: $type-size-5;
}

.representants .author__bio {
  margin-top: 10px;
  margin-bottom: 10px;

  @include breakpoint($large) {
      margin: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.representants .author__urls-wrapper {
  position: relative;
  display: block;
  vertical-align: middle;
  font-family: $sans-serif;
  z-index: 20;
  cursor: pointer;

  li:last-child {
    a {
      margin-bottom: 0;
    }
  }

  .author__urls {
    span.label {
      padding-left: 5px;
    }
  }

  @include breakpoint($large) {
    display: table-cell;
  }

  button {
    display: none;
  }
}

.representants .author__urls {
  display: block;
  position: relative;
  right: 0;
  margin-top: 0;
  padding: 10px;
  list-style-type: none;
  border: 0;
  border-radius: 0px;
  background: none;
  box-shadow: none;
  cursor: default;

  &.is--visible {
    display: none;
  }

  @include breakpoint($large) {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    box-shadow: none;
  }

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: -11px;
    left: calc(50% - 10px);
    width: 0;
    border-style: solid;
    border-width: 0 10px 10px;
    border-color: $border-color transparent;
    z-index: 0;

    @include breakpoint($large) {
      display: none;
    }
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
    width: 0;
    border-style: solid;
    border-width: 0 10px 10px;
    border-color: $background-color transparent;
    z-index: 1;

    @include breakpoint($large) {
      display: none;
    }
  }

  ul {
    padding: 10px;
    list-style-type: none;
  }

  li {
    white-space: nowrap;
  }

  a {
    display: block;
    margin-bottom: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: inherit;
    font-size: $type-size-5;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

